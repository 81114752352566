import React, { useEffect, useMemo, useRef, useState } from "react";
import Container from "../../components/TailwindCustom/Container2";
import ScratchCard from "react-scratchcard-v2";
import GameBar from "../../components/TailwindCustom/GameBar";
import InventoryGameBar from "../../components/TailwindCustom/InventoryGameBar";
import { useNavigate, useParams } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { httpRequest } from "../../helpers/api";
import { getErrorMessage } from "@qlibs/react-components";
import { getMessage } from "../../helpers/text";
import { thousandSeparator } from "../../helpers/priceFormat";
import AppButton from "../../components/TailwindCustom/AppButton";
import toast from "react-hot-toast";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";
import LogoGame from "../../assets/images/Logo.svg";
import useSound from "use-sound";
import scratchSFX from "../../assets/sound/scratchpencil.mp3";

export interface IUserData {
  userId: string;
  name: string;
  phone: string;
  token: string;
}

const ScratchPage = () => {
  const navigate = useNavigate();
  const ref = useRef<ScratchCard>(null);
  const Card = require("../../assets/images/Card_1.png");
  const scratchCard = require("../../assets/images/Card_Finish_Variant_1.png");
  const NoReward = require("../../assets/images/NoReward.png");

  const { gameCode } = useParams();
  const [sessionDataStatus, setSessionDataStatus] = React.useState<any>();
  const [completeScratch, setCompleteScratch] = React.useState("");
  const auth = useAuthUser<IUserData>();
  const [key, setKey] = React.useState(Math.random());
  const [isLoadingToClaimReward, setIsLoadingToClaimReward] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(true);
  const [reward, setReward] = useState(0);

  // let scratchSizeWidth;
  // if (window.innerWidth > 768) {
  //   scratchSizeWidth = 315;
  //   console.log("Ini PC");
  // } else {
  //   // 50vw
  //   scratchSizeWidth = window.innerWidth / 1.27;
  //   console.log("Ini Mobile");
  // }

  let scratchSizeHeight;
  if (window.innerWidth > 768) {
    scratchSizeHeight = 188;
  } else {
    scratchSizeHeight = window.innerHeight / 5;
    // scratchSizeHeight = '25vh'
  }

  const scratchSizeWidth = useMemo(() => {
    if (window.innerWidth > 390) {
      return 390 - 32;
    } else {
      return window.innerWidth - 32;
    }
  }, []);

  React.useEffect(() => {
    const getDatas = async () => {
      const response = await httpRequest.get(
        "/play/" + gameCode + "/status/" + auth?.userId
      );

      const resData = response.data.payload.payload;

      setSessionDataStatus(resData);
    };
    if (isFetch) {
      getDatas();
      setIsFetch(false);
    }

    getDatas();
  }, [isFetch]);

  useEffect(() => {
    if (sessionDataStatus && sessionDataStatus?.isClaimPage) {
      setCompleteScratch("True");
    }
  }, [sessionDataStatus]);

  const getNearestReward = () => {
    let nearestReward = null;

    if (sessionDataStatus && sessionDataStatus?.isClaimPage) {
      nearestReward = sessionDataStatus?.lastGetReward;
    } else if (
      sessionDataStatus &&
      sessionDataStatus.gamePlayer?.availableRewards
    ) {
      // Check if sessionDataStatus exists and has availableRewards
      const availableRewards = sessionDataStatus.gamePlayer.availableRewards;

      for (let i = 0; i < sessionDataStatus?.gamePlayer?.maxGameplay; i++) {
        const availableRewardsGameplay =
          availableRewards?.[`gameplay_${i + 1}`];
        if (sessionDataStatus?.gamePlayerHistory?.gameplay === i + 1) {
          nearestReward = availableRewardsGameplay[0];
        }
      }
    }

    return nearestReward;
  };
  const nearestReward = getNearestReward();

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = ""; // This line is necessary for Chrome
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    ref.current && ref.current.reset();

    return () => {
      ref.current && ref.current.reset();
    };
  }, []);

  useEffect(() => {
    setReward(sessionDataStatus?.gamePlayerHistory?.totalRewardClaimed || 0);
  }, [sessionDataStatus]);

  const postGameData = async () => {
    setIsLoading(true);
    setReward(reward + nearestReward);
    try {
      const res = await httpRequest.post(`/play/${gameCode}/start`, {
        name: auth?.name,
        phone: auth?.phone,
      });

      const resData = res.data.payload;

      // console.log('start resData: ' + JSON.stringify(resData));

      // if (resData && !resData.checkExpired[0]?.enterGame && !resData?.checkExpired[0]?.canPlay) {
      //   console.log('expired');
      //   throw new Error('expired');
      // }

      if (
        resData &&
        resData?.payload?.realRound === resData?.payload?.maxRoundPerUser
      ) {
        setIsLoadingToClaimReward(true);
        setTimeout(() => {
          setIsLoadingToClaimReward(false);
          setIsLoading(false);
          navigate(`/play/${sessionDataStatus?.game?.game_code}/claim`, {
            state: {
              currentGameplay: resData?.payload?.realGameplay,
            },
          });
        }, 3000);
      }
    } catch (error: any) {
      console.error("Error posting game data:", error);
      toast.error(getErrorMessage(getMessage(error)));
    } finally {
      setIsLoading(false);
    }
  };

  const BGReward = useMemo(() => {
    return nearestReward ? scratchCard : NoReward;
  }, [nearestReward]);

  return (
    <Container>
      <div className="flex flex-col items-center justify-between h-full py-[14px] gap-[54px]">
        <div className="flex flex-col items-center justify-between h-full">
          <div className="px-[15px] mb-6 w-full z-10">
            <GameBar sessionDataStatus={sessionDataStatus} />
          </div>
          <div className="flex flex-col items-center justify-center h-full">
            <img src={LogoGame} alt="logo" className="scale-[1.3]" />

            <h2 className="font-semibold mb-[18px] text-center text-black text-normal">
              Scratch box below to win the Prize!
            </h2>
            <div>
              <div className="mb-[18px]">
                <InventoryGameBar
                  reward={reward}
                  sessionDataStatus={sessionDataStatus}
                />
              </div>

              <div
                style={{
                  borderRadius: 2,
                  border: "2px solid #88550A",
                  background: "#88550A",
                  boxShadow: "0px 9px 0px 0px #88550A",
                }}
                className="mb-5"
              >
                <div
                  className="relative flex items-center justify-center w-full h-full overflow-hidden min-h-max scratch-card-container"
                  style={{
                    background: "#000",
                    border: "3px solid #FFBE18",
                    borderRadius: "2px",
                  }}
                >
                  <ScratchCard
                    key={key}
                    width={scratchSizeWidth}
                    height={160}
                    image={Card}
                    finishPercent={70}
                    onComplete={() => {
                      postGameData();
                      setCompleteScratch("True");
                    }}
                    ref={ref}
                  >
                    <div className="relative flex items-center justify-center w-full h-full">
                      <img
                        src={BGReward}
                        alt=""
                        className="w-full h-full px-3 py-4"
                      />
                      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                        {nearestReward ? (
                          <span className="text-black text-[50px] font-bold">
                            {thousandSeparator(nearestReward)}
                          </span>
                        ) : (
                          <span className="text-black font-bold text-[28px]">
                            Maybe next time
                          </span>
                        )}
                      </div>
                    </div>
                  </ScratchCard>
                  {isLoadingToClaimReward ? (
                    <div className="absolute top-0 left-0 z-30 w-full h-full bg-black/70">
                      <div className="grid h-full place-items-center">
                        <div className="text-white loading loading-spinner loading-md"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isLoadingToClaimReward && completeScratch === "True" ? (
                <AppButton
                  text="Play Again"
                  onClick={() => {
                    setCompleteScratch("False");
                    setIsFetch(!isFetch);
                    setKey(Math.random());
                    ref.current && ref.current.reset();
                  }}
                  loading={isLoading}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <LinkTerms theme="dark" />
        </div>
      </div>
    </Container>
  );
};

export default ScratchPage;