import React from "react";
import useSound from "use-sound";
import systemnotifSFX from '../../assets/sound/systemnotif.mp3';

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  showClose?: boolean;
};

export default function Modal({
  children,
  open,
  onClose,
  showClose = true,
}: Props) {
  const modalClass = `z-[9999] modal sm:modal-middle ${
    open ? "modal-open" : ""
  }`;

  const [PlaySystemNotif] = useSound(systemnotifSFX);

  React.useEffect(() => {
    if (open) {
      PlaySystemNotif();
    }
  }, [open]);

  return (
    <dialog id="my_modal_3" className={modalClass}>
      <div className="max-w-md bg-white modal-box p-[20px] rounded-[10px]">
        {showClose && (
          <form method="dialog" className="modal-backdrop">
            {/* if there is a button in form, it will close the modal */}
            <button
              className="absolute text-lg text-black btn btn-sm btn-ghost right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
          </form>
        )}

        {children}
      </div>
    </dialog>
  );
}
