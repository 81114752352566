import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useLocation, Navigate, useParams } from "react-router-dom";

const PrivateRoute = ({ children, loginPath }: any) => {
  const params = useParams();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (!isAuthenticated()) {
    return (
      <Navigate
        to={loginPath || "/play/" + params.gameCode}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

export default PrivateRoute;
