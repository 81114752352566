import { useEffect, useState } from "react";
import IconSuccessTransfer from "../../assets/images/icon-success-transfer.svg";
import IconWaitingTransfer from "../../assets/images/icon-waiting-transfer.svg";
import { httpRequest } from "../../helpers/api";

import { thousandSeparator } from "../../helpers/priceFormat";
import AppButton from "./AppButton";
import toast from "react-hot-toast";
import { NotSet, getErrorMessage } from "@qlibs/react-components";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { IUserData } from "../../screens/Game/ScratchPage";
import ClaimRewardModal from "../modal/ClaimRewardModal";
import { generateClaimWAURL } from '../../helpers/claimWA';

const RewardDetail = ({
  item,
  page,
  gameCode,
  onUpdate,
  maxRoundPerGameplay,
  gameTitle,
  isExpired
}: {
  item?: any;
  page?: string;
  gameCode?: string;
  onUpdate?: () => void;
  maxRoundPerGameplay?: number;
  gameTitle?: string
  isExpired?: boolean
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuthUser<IUserData>();
  const [claimPhoneNumber, setClaimPhoneNumber] = useState("");
  const [openModalClaim, setOpenModalClaim] = useState<boolean>(false);

  useEffect(() => {
    const getClaimPhoneNumber = async () => {
      try {
        const res = await httpRequest.get("/app-configs/CLAIM_PHONE_NUMBER");

        setClaimPhoneNumber(res.data.payload.value);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    };

    getClaimPhoneNumber();
  }, []);

  const doClaimReward = async (historyId: string, isToast?: boolean) => {
    if (!historyId) return;
    setIsLoading(true);
    try {
      await httpRequest.post(`/play/${gameCode}/claim-reward`, {
        playerId: auth?.userId,
        playerHistoryId: historyId,
      });
      if (isToast) {
        toast.success("Success retry claim reward!");
      }
      onUpdate?.();
    } catch (error: any) {
      console.error("Error claim reward:", error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        background: '#FFFFFF',
        border: '2px solid #EBCE6A',
        boxShadow: '0px 4px 0px #BF7607, inset 0px 5px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 20,
        padding: 15,
      }}
      className="space-y-3 font-bold overflow-auto h-[40vh] z-50"
    >
      {gameTitle && (
        <p
          className="text-center text-black font-bold"
          style={{ fontSize: '38px' }}
        >
          {gameTitle}
        </p>
      )}

      <div
        style={{
          background: '#1E1E1E',
          border: '2px solid #EBCE6A',
          boxShadow: '0px 4px 0px #BF7607',
          borderRadius: 100,
        }}
        className="font-bold py-[4px] px-[4px]"
      >
        <div className="flex items-center justify-between">
          <span className="text-start text-white font-bold text-[14px] pl-[4px]">
            Gameplay {item?.gameplay || 0}
          </span>
          {item?.transferAt && item?.rewardClaimedAt ? (
            <div className="p-[2px] bg-[#43A047] flex items-center gap-1 rounded-full">
              <img
                src={IconSuccessTransfer}
                alt="IconSuccessTransfer"
                className="w-[14px] h-[14px] pl-[1px]"
              />
              <p className="text-white font-semibold text-[11px] mr-1">
                Transfered
              </p>
            </div>
          ) : !item?.transferAt && item?.rewardClaimedAt ? (
            <div className="p-[2px] bg-[#FFB300] flex items-center gap-1 rounded-full">
              <img
                src={IconWaitingTransfer}
                alt="IconWaitingTransfer"
                className="w-[14px] h-[14px]"
              />
              <p className="text-black font-semibold text-[11px] mr-1">
                Waiting for transfer
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div
        style={{
          height: 200,
          width: '100%',
          overflowY: 'scroll',
        }}
      >
        {[...Array(maxRoundPerGameplay)].map((_, index) => {
          return (
            <>
              <div className="flex items-center justify-start p-[10px]">
                <span
                  className="text-start text-black font-bold w-[50%]"
                  style={{ fontSize: '14px' }}
                >
                  Round {index + 1 || 0}
                </span>

                <span
                  className="text-start text-black font-bold w-[50%]"
                  style={{ fontSize: '14px' }}
                >
                  {item?.rewardClaimed_AllRounds?.[index] === 0 ? (
                    thousandSeparator(0)
                  ) : item?.rewardClaimed_AllRounds?.[index] ? (
                    thousandSeparator(
                      item?.rewardClaimed_AllRounds?.[index] || 0
                    )
                  ) : (
                    <NotSet value="Not played yet" />
                  )}
                </span>
              </div>
              <hr className="border-1" />
            </>
          );
        })}
      </div>

      {/* <div>
        {item &&
        item?.rewardClaimed_AllRounds &&
        item?.rewardClaimed_AllRounds?.length > 0
          ? item?.rewardClaimed_AllRounds?.map((v: number, idx: number) => (
              <>
                <div className="flex items-center justify-start p-[10px]">
                  <span
                    className="text-start text-black font-bold w-[50%]"
                    style={{ fontSize: "14px" }}
                  >
                    Round {idx + 1 || 0}
                  </span>

                  <span
                    className="text-start text-black font-bold w-[50%]"
                    style={{ fontSize: "14px" }}
                  >
                    {thousandSeparator(v || 0) || 0}
                  </span>
                </div>
                <hr className="border-1" />
              </>
            ))
          : null}
      </div> */}

      {item?.rewardClaimed_AllRounds?.length === maxRoundPerGameplay ? (
        page === 'profile' && !item?.transferAt && !item?.rewardClaimedAt ? (
          <a
            href={generateClaimWAURL({
              claimPhoneNumber,
              gameplay: item?.gameplay,
              gameCode: gameCode || '..',
              totalRewardClaimed: item?.totalRewardClaimed || 0,
              name: auth?.name || '..',
              playerPhone: auth?.phone || '..',
            })}
            className="block w-full"
            target="_blank"
            rel="noreferrer noopener"
          >
            {isExpired &&
              <span className="text-left text-red-500 font-extrabold">
                The reward has already expired!
              </span>
            }

            {!isExpired &&
              <AppButton
                text="Claim My Rewards"
                style={{
                  height: 36,
                }}
                onClick={() => doClaimReward(item.id)}
                loading={isLoading}
                disabled={isExpired}
              />
            }

          </a>
        ) : page === 'profile' && !item?.transferAt && item?.rewardClaimedAt ? (
          <button
            className="text-[#2196F3] flex items-center gap-1 justify-center underline text-sm font-bold w-full text-center h-[28px]"
            onClick={() => setOpenModalClaim(true)}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="loading loading-spinner loading-xs"></span>
            )}{' '}
            <span>Retry Claim Rewards</span>
          </button>
        ) : null
      ) : null}

      <ClaimRewardModal
        openModalClaim={openModalClaim}
        setOpenModalClaim={setOpenModalClaim}
        item={item}
      />
    </div>
  );
};

export default RewardDetail;
