import { Navigate, useParams } from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { ReactElement } from "react";

export interface IProps {
  children: ReactElement;
}

const PublicRoute: React.FC<IProps> = ({ children }) => {
  const params = useParams();
  // const isAuthenticated = useIsAuthenticated();

  // if (isAuthenticated()) {
  //   return <Navigate to={'/play/' + params.gameCode} />;
  // }

  return children;
};

export default PublicRoute;
