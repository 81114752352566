import { BrowserRouter, Routes, Route } from "react-router-dom";
// import AuthPage from '../screens/Game/AuthPage.tsx';
// import ScratchPage from '../screens/Game/ScratchPage.jsx';
// import TermsCondition from '../screens/About/TermsCondition.tsx';
// import ClaimPage from '../screens/Reward/ClaimPage.tsx';
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Landing from "../screens/Home/Landing";
import AuthPage from "../screens/Game/AuthPage";
import ScratchPage from "../screens/Game/ScratchPage";
import ClaimPage from "../screens/Reward/ClaimPage";
import TermsCondition from "../screens/About/TermsCondition";
import GameplayHistories from "../screens/Game/GameplayHistories";
import LobbyMusic from "../components/AudioTrigger/LobbyMusic";

// const Landing = React.lazy(() => import("../screens/Home/Landing"));
// const AuthPage = React.lazy(() => import("../screens/Game/AuthPage"));
// const ScratchPage = React.lazy(() => import("../screens/Game/ScratchPage"));
// const TermsCondition = React.lazy(
//   () => import("../screens/About/TermsCondition")
// );
// const ClaimPage = React.lazy(() => import("../screens/Reward/ClaimPage"));

const RootNavigator = () => {
  return (
    <BrowserRouter>
      <LobbyMusic
    
      />
      <Routes>

        <Route
          path="/"
          element={
            <PublicRoute>
              <Landing />
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Landing />
            </PublicRoute>
          }
        />

        {/* // APP CORE // */}

        <Route
          path="/terms"
          element={
            <PublicRoute>
              <TermsCondition />
            </PublicRoute>
          }
        />

        <Route
          path="/play/:gameCode"
          element={
            <PublicRoute>
              <AuthPage />
            </PublicRoute>
          }
        />

        <Route
          path="/play/:gameCode/claim"
          element={
            <PrivateRoute>
              <ClaimPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/play/:gameCode/histories"
          element={
            <PrivateRoute>
              <GameplayHistories />
            </PrivateRoute>
          }
        />

        <Route
          path="/play/:gameCode/start"
          element={
            <PrivateRoute>
              <ScratchPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/play/:gameCode/claim"
          element={
            <PrivateRoute>
              <ClaimPage />
            </PrivateRoute>
          }
        />

        {/* // ABOUT // */}
      </Routes>
    </BrowserRouter>
  );
};

// old root navigator
// {/* // Home */}
// <Route path='/' element={<Landing />} />

// {/* Game UI Home */}
// <Route path='/game/:gameId' element={<AuthPage />} />
// <Route path='/game/:gameId/play' element={<ScratchPage />} />
// <Route path='/game/claim' element={<ClaimPage />} />

// {/* About */}
// <Route path='/game/terms' element={<TermsCondition />} />

export default RootNavigator;
