import { useState } from "react";
import { httpRequest } from "../helpers/api";
import {
  getErrorMessage,
  saveToken,
  BaseResponseProps,
} from "@qlibs/react-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { getMessage } from "../helpers/text";

type Props = {
  apiLoginUrl?: string;
  apiGetMyProfileUrl?: string;
};

export default function useAuthApp(props?: Props) {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const { gameCode } = useParams();

  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [authError, setAuthError] = useState<string>("");

  // const doLogin = async (data: { email: string; password: string }, callback?: () => void) => {
  const doLogin = async (
    data: { name: string; phone: string },
    callback?: () => void
  ) => {
    setIsAuthLoading(true);
    try {
      const resultAuthLogin = await httpRequest.post<
        BaseResponseProps<{
          access_token: string;
          refresh_token: string;
        }>
      >(
        props?.apiLoginUrl ||
          process.env.REACT_APP_BASE_URL + "/play/" + gameCode + "/start",
        { ...data, initGame: true }
      );

      if (!resultAuthLogin) {
        // message.error("Login failed. Empty response.");
        setAuthError("Login failed. Empty response.");

        return;
      }

      if (resultAuthLogin) {
        saveToken(resultAuthLogin.data.payload.access_token);
      }

      const resProfile = await axios.get<
        BaseResponseProps<{
          token: string;
          name: string;
          phone: string;
        }>
      >(
        props?.apiGetMyProfileUrl ||
          process.env.REACT_APP_BASE_URL + "/users/me",
        {
          headers: {
            Authorization:
              "Bearer " + resultAuthLogin.data.payload.access_token,
          },
        }
      );

      if (!resProfile) {
        // message.error("Login failed. No profile.");
        setAuthError("Login failed. No profile.");

        return;
      }

      if (
        signIn({
          // token: resultAuthLogin.data.payload.access_token,
          // expiresIn: 10000,
          // tokenType: 'Bearer',
          // authState: { token: resultAuthLogin.data.payload.access_token },
          auth: {
            token: resultAuthLogin.data.payload.access_token,
            type: "Bearer",
          },
          userState: resProfile.data.payload,
          // authState: { token: resultAuthLogin.data.payload.token },
          // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
          // refreshTokenExpireIn: res.data.refreshTokenExpireIn, // Only if you are using refreshToken feature
        })
      ) {
        // Redirect or do-something
        // console.log(resProfile)
        if (callback) {
          callback();
        } else {
          navigate("/play/" + gameCode + "/start", { replace: true });
        }
        // message.success('Welcome to ' + process.env.REACT_APP_WEBSITE_NAME);
      } else {
        setAuthError("Login failed.");
        // message.error("Login failed.");
        //Throw error
      }
    } catch (err: any) {
      // message.error(getErrorMessage(getMessage(err)));
      setAuthError(getErrorMessage(getMessage(err)));
      console.log(err);
    }

    setIsAuthLoading(false);
  };

  return {
    isAuthLoading,
    doLogin,
    authError,
    setAuthError,
  };
}
