import React from 'react'
import Sound from 'react-sound';
import lobbyMusic from '../../assets/music/lobby.mp3';
import musicOn from '../../assets/images/music_on.png';
import musicOff from '../../assets/images/music_off.png';

const LobbyMusic = () => {

    const [isMusicOn, setIsMusicOn] = React.useState(false);

    React.useEffect(() => {
        localStorage.setItem('musicStatus', JSON.stringify(isMusicOn));
    }, [isMusicOn]);


    const handleMusicToggle = () => {
        setIsMusicOn(!isMusicOn);
    };

    return (
        <>
            <div>
                <img
                    src={isMusicOn ? musicOn : musicOff}
                    alt="music"
                    style={{
                        width: '40px',
                        height: '40px',
                        position: 'fixed',
                        bottom: '10px',
                        right: '10px',
                    }}
                    className='cursor-pointer'
                    onClick={handleMusicToggle}
                />
            </div>

            <Sound
                url={lobbyMusic}
                playStatus={isMusicOn ? Sound.status.PLAYING : Sound.status.STOPPED}
                loop={true}
            />
        </>
    );
}

export default LobbyMusic