import React, { useState } from "react";
import Container from "../../components/TailwindCustom/Container2";
import { useNavigate, useParams } from "react-router-dom";
import IconArrowBack from "../../assets/images/icon-arrow-back.svg";
import Icon_User from "../../assets/images/Icon_User.svg";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { IUserData } from "./ScratchPage";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { httpRequest } from "../../helpers/api";
import toast from "react-hot-toast";
import { getErrorMessage } from "@qlibs/react-components";
import { getMessage } from "../../helpers/text";
import RewardDetail from "../../components/TailwindCustom/RewardDetail";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";

export default function GameplayHistories() {
  const auth = useAuthUser<IUserData>();
  const { gameCode } = useParams();

  const navigate = useNavigate();
  const signOut = useSignOut();

  const [dataClaimReward, setDataClaimReward] = React.useState<any>();
  const [isFetch, setIsFetch] = useState(true);

  React.useEffect(() => {
    const getDatas = async () => {
      try {
        const response = await httpRequest.get(
          "/play/" + gameCode + "/claim-reward/" + auth?.userId
        );

        setDataClaimReward(response.data.payload.payload);
      } catch (error: any) {
        toast.error(getErrorMessage(getMessage(error)));
      }
    };

    if (isFetch) {
      getDatas();
      setIsFetch(false);
    }
  }, [auth?.userId, gameCode, isFetch]);

  const handleLogout = () => {
    signOut();
    navigate("/play/" + gameCode);
  };

  return (
    <Container withBg={false}>
      <div className="flex justify-center items-center py-6 border-b border-[#E8ECF3] sticky top-0 left-0 bg-white">
        <button
          onClick={() => navigate(-1)}
          className="w-full pl-6"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            top: "45%",
          }}
        >
          <img src={IconArrowBack} alt="icon arrow back" />
        </button>
        <h2 className="font-semibold text-left text-black ">
          Gameplay Histories
        </h2>
      </div>
      <div className="p-5">
        <div className="flex items-center justify-between gap-3 mb-6">
          <div className="flex items-center space-x-1">
            <img src={Icon_User} alt="User" className="w-14" />
            <div className="leading-5">
              <p className="font-bold line-clamp-1 text-black text-[14px]">
                {auth?.name || "-"}
              </p>
              <p className="font-bold text-black text-[14px]">
                {auth?.phone || "-"}
              </p>
            </div>
          </div>

          <div
            onClick={handleLogout}
            className="text-sm underline cursor-pointer font-bold text-[#EA1B1B]"
          >
            LOGOUT
          </div>
        </div>
        <div className="flex flex-col justify-between h-full gap-6">
          {dataClaimReward &&
            dataClaimReward?.gamePlayerHistories &&
            dataClaimReward?.gamePlayerHistories.length > 0 ? (
            dataClaimReward?.gamePlayerHistories
              ?.sort((a: any, b: any) => b.gameplay - a.gameplay)
              ?.map((item: any) => (
                <RewardDetail
                  item={item}
                  key={item.id}
                  page="profile"
                  gameCode={gameCode}
                  onUpdate={() => setIsFetch(!isFetch)}
                  maxRoundPerGameplay={
                    dataClaimReward?.game?.max_round_per_gameplay_per_user
                  }
                  isExpired={
                    dataClaimReward?.gameplay && dataClaimReward?.gameplay.length > 0 && 
                    dataClaimReward?.gameplay.find((it: any) => it.gameplay === item.gameplay)?.expiredAt !== undefined &&
                    new Date(dataClaimReward?.gameplay.find((it: any) => it.gameplay === item.gameplay)?.expiredAt) <= new Date() 
                    ? true : false
                  }
                />
              ))
          ) : (
            <p className="text-center text-black">History not found</p>
          )}

          <div className="mt-6">
            <LinkTerms theme="dark" />
          </div>
        </div>
      </div>
    </Container>
  );
}
