import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/TailwindCustom/Container2";
import Logo from "../../assets/images/Logo.svg";
import Coin from "../../assets/images/Coin.svg";
import IconCopy from "../../assets/images/icon-copy.svg";
import Pride from "react-canvas-confetti/dist/presets/pride";
import randomInRange from "react-canvas-confetti/dist/helpers/randomInRange.js";
import RewardDetail from "../../components/TailwindCustom/RewardDetail";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { IUserData } from "../Game/ScratchPage";
import { httpRequest } from "../../helpers/api";
import { thousandSeparator } from "../../helpers/priceFormat";
import { getErrorMessage } from "@qlibs/react-components";
import { getMessage } from "../../helpers/text";
import Modal from "../../components/modal/Modal";
import AppButton from "../../components/TailwindCustom/AppButton";
import toast from "react-hot-toast";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";
import { generateClaimWAURL } from '../../helpers/claimWA';

const ClaimPage = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const { gameCode } = useParams();
  const { state } = useLocation();
  const [openModalClaim, setOpenModalClaim] = useState(false);
  const [openModalPlayAgain, setOpenModalPlayAgain] = useState(false);

  const auth = useAuthUser<IUserData>();

  const [dataClaimReward, setDataClaimReward] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isClaim =
    state?.currentGameplay === dataClaimReward?.gamePlayer?.maxGameplay;
  const [claimPhoneNumber, setClaimPhoneNumber] = useState("");
  // const [isExpired, setIsExpired] = useState<boolean>(false);

  React.useEffect(() => {
    const getDatas = async () => {
      try {
        const response = await httpRequest.get(
          "/play/" +
          gameCode +
          "/claim-reward/" +
          auth?.userId +
          `?gameplay=${state?.currentGameplay}`
        );

        console.log('response: ' + JSON.stringify(response));

        // const expired = response.data.payload.payload.gameplay.expiredAt;

        // if (expired) {
        //   const expiredDate = new Date(expired);
        //   const today = new Date();

        //   setIsExpired(today < expiredDate ? false : true);
        // }

        setDataClaimReward(response.data.payload.payload);
      } catch (error: any) {
        toast.error(getErrorMessage(getMessage(error)));
      }
    };

    getDatas();
  }, [auth?.userId, gameCode, state.currentGameplay]);

  React.useEffect(() => {
    const getClaimPhoneNumber = async () => {
      try {
        const res = await httpRequest.get("/app-configs/CLAIM_PHONE_NUMBER");

        setClaimPhoneNumber(res.data.payload.value);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    };

    getClaimPhoneNumber();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = ""; // This line is necessary for Chrome
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const doClaimReward = async () => {
    setIsLoading(true);
    try {
      await httpRequest.post(`/play/${gameCode}/claim-reward`, {
        playerId: auth?.userId,
        playerHistoryId: dataClaimReward?.gamePlayerHistory?.id,
      });

      // setIsSuccessModalOpen(true);
      setOpenModalClaim(true);
    } catch (error: any) {
      console.error("Error claim reward:", error);
      toast.error(getErrorMessage(getMessage(error)));
    } finally {
      setIsLoading(false);
    }
  };

  const doOk = () => {
    signOut();
    setOpenModalPlayAgain(false);

    navigate(`/play/${gameCode}`, {
      replace: true,
    });
  };

  const doSendMessage = () => {
    if (isClaim) {
      doOk();
    } else {
      setOpenModalPlayAgain(true);
    }
    setOpenModalClaim(false);
  };

  const doCopy = () => {
    navigator.clipboard.writeText(claimPhoneNumber ?? "");
    toast.success("Copy to Clipboard!");
  };

  const doPlayAgain = async () => {
    setIsLoading(true);
    try {
      await httpRequest.post("/play/" + gameCode + "/start", {
        name: auth?.name,
        phone: auth?.phone,
        initGame: true,
      });
      navigate(`/play/${gameCode}/start`, {
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setOpenModalPlayAgain(false);
    }
  };

  const decorateOptions = (defaultOptions: any) => {
    return {
      ...defaultOptions,
      colors: ["#2767F0", "#007947", "#ED3CE8", "#E88A34", "#9F0030"],
      scalar: randomInRange(0.5, 1.5),
      particleCount: randomInRange(1, 10),
    };
  };

  const controller: any = useRef();

  const onInitHandler = ({ conductor }: any) => {
    controller.current = conductor;
  };

  useEffect(() => {
    setTimeout(() => {
      controller.current.pause();
    }, 2000);
  }, []);

  return (
    <>
      <Container bgNoLogo classNameInner="relative">
        <Pride
          autorun={{ speed: 30 }}
          decorateOptions={decorateOptions}
          onInit={onInitHandler}
          style={{
            width: '100%',
            position: 'absolute',
            height: '100%',
            top: 0,
            left: 0,
          }}
        />
        <div className="flex flex-col justify-between px-[25px] py-4 h-full">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <span className="mt-4 text-xl font-bold text-center text-black line-clamp-1 font-outline-white-large ">
                Hi {auth?.name},
              </span>
              <span className="text-xl font-bold text-center text-black font-outline-white-large">
                Congratulation you won
              </span>
            </div>
            <div className="flex flex-col items-center justify-center my-3">
              {/* <img
                src={Sparkle_Effect}
                alt="Sparkle_Effect"
                className="w-[80vw]"
                style={{ position: "absolute", zIndex: 30 }}
              /> */}

              <span
                className="mx-10 font-bold text-center font-outline-4-orange"
                style={{
                  color: '#FFFFFF',
                  fontSize: 60,
                  textShadow: '0px 3.09px 0px rgba(0, 0, 0, 0.25)',
                }}
              >
                {dataClaimReward &&
                  thousandSeparator(
                    dataClaimReward?.gamePlayerHistory?.totalRewardClaimed ?? 0
                  )}
              </span>

              <span
                className="mx-10 font-bold text-center "
                style={{
                  position: 'absolute',
                  lineHeight: '150%',
                  color: '#FFFFFF',
                  textShadow: '0px 3.09px 0px rgba(0, 0, 0, 1)',
                  fontSize: 60,
                }}
              >
                {dataClaimReward &&
                  thousandSeparator(
                    dataClaimReward?.gamePlayerHistory?.totalRewardClaimed ?? 0
                  )}
              </span>
            </div>
            <div
              className="w-full"
              style={{
                zIndex: 9999,
              }}
            >
              {dataClaimReward && (
                <RewardDetail
                  item={dataClaimReward?.gamePlayerHistory}
                  maxRoundPerGameplay={
                    dataClaimReward?.game?.max_round_per_gameplay_per_user
                  }
                  gameTitle={dataClaimReward?.game?.title}
                />
              )}
            </div>
          </div>
          <div className="z-10 flex flex-col items-center">
            {/* {isExpired &&
              <span className="text-left text-red-500 font-extrabold">
                The reward has already expired!
              </span>
            } */}
            <AppButton
              text="Claim My Reward"
              onClick={doClaimReward}
              loading={isLoading}
              className="mb-7"
              // disabled={isExpired}
            />

            <LinkTerms theme="dark" />
          </div>
        </div>
      </Container>
      {/* Modal Claim Reward */}
      <Modal
        open={openModalClaim}
        onClose={() => setOpenModalClaim(false)}
        showClose={false}
      >
        <div className="flex flex-col items-center">
          <img src={Coin} alt="Coin" className="scale-[1.1]" />
          <div className="mb-[30px]">
            <h3 className="font-semibold text-lg mb-[10px] text-black text-center">
              Claim Rewards Instructions
            </h3>
            <p className="text-xs text-center text-black mb-[10px]">
              In order to claim your rewards, please contact us via Whatsapp to
              this number
            </p>
            <button
              className="flex items-center hover:bg-gray-300 justify-center space-x-2 bg-[#E8ECF3] rounded-lg w-full h-[57px]"
              onClick={doCopy}
            >
              <span className="text-sm font-bold text-black">
                {claimPhoneNumber || '0'}
              </span>
              <span className="text-xs font-bold flex items-center space-x-1 text-[#E53935]">
                <img src={IconCopy} alt="" className="w-[16px] h-[16px]" />
                <span>Copy</span>
              </span>
            </button>
          </div>
          <a
            href={generateClaimWAURL({
              claimPhoneNumber,
              gameplay: state.currentGameplay,
              gameCode: gameCode || '',
              totalRewardClaimed:
                dataClaimReward?.gamePlayerHistory?.totalRewardClaimed ?? 0,
              name: auth?.name || '..',
              playerPhone: auth?.phone || '..',
            })}
            className="w-full"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppButton
              text="Contact Us to Claim Reward"
              onClick={doSendMessage}
            />
          </a>
        </div>
      </Modal>

      {/* Modal Play Again */}
      <Modal
        open={openModalPlayAgain}
        onClose={() => setOpenModalPlayAgain(false)}
        showClose={false}
      >
        <div className="flex flex-col items-center">
          <img src={Logo} alt="Logo" className="scale-[1.1]" />
          <div className="mb-[30px]">
            <h3 className="font-semibold text-lg mb-[10px] text-black text-center">
              Do you want to play again?
            </h3>
            <p className="text-xs text-center text-black">
              You still have{' '}
              {dataClaimReward?.gamePlayer?.maxGameplay -
                state?.currentGameplay}{' '}
              gameplay slots ({state?.currentGameplay}/
              {dataClaimReward?.gamePlayer?.maxGameplay})
            </p>
          </div>
          <div className="grid w-full grid-cols-2 gap-2">
            <AppButton text="Not Now" buttoType="default" onClick={doOk} />

            <AppButton
              text="Play Again"
              onClick={doPlayAgain}
              loading={isLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClaimPage;
