import { useEffect, useState } from "react";
import IconArrowBack from "../../assets/images/icon-arrow-back.svg";
import Container from "../../components/TailwindCustom/Container2";
import { useNavigate } from "react-router-dom";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";
import toast from "react-hot-toast";
import { getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";

const TermsCondition = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isIconLoaded, setIsIconLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = IconArrowBack;
    img.onload = () => setIsIconLoaded(true);
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await httpRequest.get("/app-configs/TERMS_AND_CONDITIONS");

        setTerms(res.data.payload.value);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Container withBg={false}>
      {isIconLoaded ? (
        <>
          <div className="flex justify-center items-center py-6 border-b border-[#E8ECF3] sticky top-0 left-0 bg-white">
            <button
              onClick={() => navigate(-1)}
              className="w-full pl-6"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: "45%",
              }}
            >
              <img src={IconArrowBack} alt="icon arrow back" />
            </button>
            <h2 className="font-semibold text-left text-black ">
              Terms & Conditions
            </h2>
          </div>

          <div className="h-full p-5 overflow-x-hidden">
            {isLoading ? (
              <div className="flex justify-center">
                <div className="loading loading-spinner loading-md text-[#B81313]"></div>
              </div>
            ) : (
              <div className="flex flex-col justify-between h-full">
                <div
                  className="text-black unreset custom-container"
                  dangerouslySetInnerHTML={{ __html: terms }}
                ></div>

                <div className="invisible mt-4">
                  <LinkTerms theme="dark" />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen bg-yellow-200">
          <div className="text-center">
            <span className="loading loading-spinner text-error text-2xl w-[10vw]" />
            <br />
            <span className="font-bold text-error">Hang In There...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TermsCondition;
