  import React, { useState } from 'react';
  import RootNavigator from "./navigation/RootNavigator.tsx";
  import AuthProvider from "react-auth-kit";
  import createStore from "react-auth-kit/createStore";
  import { Toaster } from "react-hot-toast";

  function App() {
    const store = createStore({
      authName: "__auth",
      authType: "localstorage",
    });

    return (
      <AuthProvider store={store}>
        <RootNavigator />
        <Toaster position="top-center" />
      </AuthProvider>
    );
  }

  export default App;