import { useMemo } from "react";
import useSound from "use-sound";
import clickSFX from "../../assets/sound/coin8bit.mp3";

type Props = {
  text: string;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit";
  disabled?: boolean;
  buttoType?: "default" | "outlined" | "primary";
  loading?: boolean;
  height?: number;
  style?: React.CSSProperties;
};

export default function AppButton({
  text,
  onClick,
  className,
  type = "button",
  disabled,
  buttoType = "primary",
  loading,
  style,
}: Props) {
  let btnClass = useMemo(() => {
    let classBtn = "";
    let styleBtn = {};

    switch (buttoType) {
      case "primary":
        classBtn =
          "bg-[#EA1B1B] btn-error disabled:bg-[#EA1B1B] disabled:text-white";
        styleBtn = {
          ...styleBtn,
          border: "2px solid #FF5858",
          boxShadow: "0px 4px 0px 0px #8E0C0C",
        };
        break;
      case "outlined":
        classBtn =
          "btn-outline btn-error hover:text-white disabled:bg-white disabled:text-red-500";
        styleBtn = {
          ...styleBtn,
          border: "2px solid #FF5858",
          boxShadow: "0px 4px 0px 0px #8E0C0C",
        };
        break;

      default:
        classBtn = "bg-[#414141]";
        styleBtn = {
          ...styleBtn,
          border: "2px solid #262626",
          boxShadow: "0px 4px 0px 0px #262626",
        };
        break;
    }

    return {
      classBtn,
      styleBtn,
    };
  }, [buttoType]);

  const [play] = useSound(clickSFX);

  const handleButtonClick = () => {
    const musicStatus = localStorage.getItem("musicStatus");
    if (musicStatus === "true") {
      play();
    }
    onClick && onClick();
  };
  
  return (
    <button
      onClick={handleButtonClick}
      type={type}
      disabled={disabled || loading}
      className={`${className ? className : ""} ${
        btnClass.classBtn
      } btn w-full h-[56px] text-[16px] font-semibold text-white text-center rounded-[4px]`}
      style={{ ...btnClass.styleBtn, ...style }}
    >
      {loading && <span className="loading loading-spinner"></span>} {text}
    </button>
  );
}
