import React, { useState } from 'react'
import Modal from './Modal'
import Coin from "../../assets/images/Coin.svg";
import IconCopy from "../../assets/images/icon-copy.svg";
import AppButton from '../TailwindCustom/AppButton';
import { httpRequest } from '../../helpers/api';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { IUserData } from '../../screens/Game/ScratchPage';
import { useLocation, useParams } from 'react-router-dom';
import { getErrorMessage } from '@qlibs/react-components';
import { getMessage } from '../../helpers/text';
import toast from 'react-hot-toast';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import thousandSeparator from '../../helpers/priceFormat';
import { generateClaimWAURL } from '../../helpers/claimWA';

// const ClaimRewardModal = () => {
// const StartModal: React.FC<StartModalProps> = ({ showModal, setShowModal }) => {

interface ClaimRewardModalProps {
    openModalClaim: boolean;
    setOpenModalClaim: React.Dispatch<React.SetStateAction<boolean>>;
    item?: any;
}

const ClaimRewardModal: React.FC<ClaimRewardModalProps> = ({ openModalClaim, setOpenModalClaim, item }) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const auth = useAuthUser<IUserData>();
    const { gameCode } = useParams();
    // const [openModalClaim, setOpenModalClaim] = useState(false);
    const [dataClaimReward, setDataClaimReward] = React.useState<any>();
    const [claimPhoneNumber, setClaimPhoneNumber] = useState("");


    React.useEffect(() => {
        const getClaimPhoneNumber = async () => {
            try {
                const res = await httpRequest.get("/app-configs/CLAIM_PHONE_NUMBER");

                setClaimPhoneNumber(res.data.payload.value);
            } catch (error) {
                toast.error(getErrorMessage(error));
            } finally {
                setIsLoading(false);
            }
        };

        getClaimPhoneNumber();
    }, []);


    const doCopy = () => {
        navigator.clipboard.writeText(claimPhoneNumber ?? "");
        toast.success("Copy to Clipboard!");
    };


    return (
        <Modal
            open={openModalClaim}
            onClose={() => setOpenModalClaim(false)}
            showClose={false}
        >
            <div className="flex flex-col items-center">
                <img src={Coin} alt="Coin" className="scale-[1.1]" />
                <div className="mb-[30px]">
                    <h3 className="font-semibold text-lg mb-[10px] text-black text-center">
                        Claim Rewards Instructions
                    </h3>
                    <p className="text-xs text-center text-black mb-[10px]">
                        In order to claim your rewards, please contact us via Whatsapp to
                        this number
                    </p>
                    <button
                        className="flex items-center hover:bg-gray-300 justify-center space-x-2 bg-[#E8ECF3] rounded-lg w-full h-[57px]"
                        onClick={doCopy}
                    >
                        <span className="text-sm font-bold text-black">
                            {claimPhoneNumber || '0'}
                        </span>
                        <span className="text-xs font-bold flex items-center space-x-1 text-[#E53935]">
                            <img src={IconCopy} alt="" className="w-[16px] h-[16px]" />
                            <span>Copy</span>
                        </span>
                    </button>
                </div>
                <a
                    href={generateClaimWAURL({
                        claimPhoneNumber,
                        gameplay: item?.gameplay,
                        gameCode: gameCode || '',
                        totalRewardClaimed: item?.totalRewardClaimed || 0,
                        name: auth?.name || '..',
                        playerPhone: auth?.phone || '..',
                    })}
                    className="w-full"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <AppButton
                        text="Contact Us to Claim Reward"
                        onClick={() => setOpenModalClaim(false)}
                    />
                </a>
            </div>
        </Modal>
    )
}

export default ClaimRewardModal