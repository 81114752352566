import React, { useState, useEffect } from 'react';
import Background from "../../assets/images/main-background.svg";
import BackgroundNoLogo from "../../assets/images/background-games-no-logo.svg";
import BackgroundPreload from "../../assets/images/main-background-preload.jpg"

interface IProps {
  children: React.ReactNode;
  withBg?: boolean;
  className?: string;
  classNameInner?: string;
  bgNoLogo?: boolean;
}

const Container: React.FC<IProps> = ({
  children,
  withBg = true,
  className,
  bgNoLogo,
  classNameInner,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = bgNoLogo ? BackgroundNoLogo : Background;
    img.onload = () => setIsImageLoaded(true);
  }, [bgNoLogo]);

  return (
    <React.Fragment>
      <div className="flex justify-center bg-black">
        <div
          className={`w-full max-w-[390px] ${classNameInner ? classNameInner : ""
            }`}
        >
          {!isImageLoaded && (
            <div className="flex justify-center items-center h-screen bg-yellow-200"
              style={{
                backgroundImage: `url(${BackgroundPreload})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className='text-center'>
                <span className="loading loading-spinner text-error text-2xl w-[10vw]" />
                <br />
                <span className="text-error font-bold">Hang In There...</span>
              </div>

            </div>
          )}
          {withBg ? (
            <div
              className={`${className ? className : ""
                } layout-game container h-screen overflow-y-scroll bg-center bg-no-repeat bg-cover`}
              style={{
                backgroundImage: `url(${bgNoLogo ? BackgroundNoLogo : Background
                  })`,
              }}
            >
              {isImageLoaded && children}
            </div>
          ) : (
            <div
              className={`
            ${className ? className : ""
                } container h-screen overflow-scroll bg-white`}
            >
              {isImageLoaded && children}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Container;