import { Link } from "react-router-dom";
import { appVersion } from "../../helpers/constant";
import useSound from "use-sound";
import clickSFX from "../../assets/sound/coin8bit.mp3";

export default function LinkTerms({ theme }: { theme: "light" | "dark" }) {

  const [play] = useSound(clickSFX);

  const handleLinkClick = () => {
    const musicStatus = localStorage.getItem('musicStatus');
    if (musicStatus === 'true') {
      play();
    }
  };

  return (
    <div className="flex items-center justify-center space-x-2 h-[5vh]">
      <Link
        to="/terms"
        className={`text-xs ${
          theme === "light" ? "text-white" : "text-black"
        } underline mb-1`}
        onClick={handleLinkClick}
      >
        Terms & Conditions
      </Link>
      <span
        className={`pb-1 ${theme === "light" ? "text-white" : "text-black"}`}
      >
        |
      </span>
      <p
        className={`text-xs ${theme === "light" ? "text-white" : "text-black"}`}
      >
        v{appVersion}
      </p>
    </div>
  );
}
