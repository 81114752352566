import React from "react";
import Icon_User from "../../assets/images/Icon_User.svg";
// import ProfileModal from "../modal/ProfileModal";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";
import useSound from 'use-sound';
import clickSFX from '../../assets/sound/coin8bit.mp3';

interface IUserData {
  name: string;
  phone: string;
}

interface IGameBarProps {
  sessionDataStatus: any;
}

const GameBar: React.FC<IGameBarProps> = ({ sessionDataStatus }) => {
  const navigate = useNavigate();
  const auth = useAuthUser<IUserData>();
  const [playClick] = useSound(clickSFX);

  const handleUserIconClick = () => {
    const musicStatus = localStorage.getItem('musicStatus');
    if (musicStatus === 'true') {
      playClick();
    }
    navigate(`/play/${sessionDataStatus?.game?.game_code}/histories`);
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div
        className="flex items-center gap-1 cursor-pointer"
        onClick={handleUserIconClick}
      >
        <img src={Icon_User} alt="User" className="w-[34px] h-[34px]" />
        <div className="leading-none">
          <p className="font-[900] line-clamp-1 text-black text-[16px] uppercase font-outline-white">
            {auth?.name || "-"}
          </p>
          <p className="font-[900] text-black text-[16px] font-outline-white">
            {auth?.phone || "-"}
          </p>
        </div>
      </div>
      <div
        onClick={handleUserIconClick}
        className="px-[10px] border cursor-pointer rounded-full border-[#B81313] flex items-center gap-2 justify-between min-w-[120px] py-[5px] text-white font-[900] bg-[#EA1B1B]"
      >
        <span>Gameplay</span>
        <span>{sessionDataStatus?.gamePlayerHistory?.gameplay || "-"}</span>
      </div>
    </div>
  );
};

export default GameBar;
