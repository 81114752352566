import thousandSeparator from './priceFormat';

export function generateClaimWAURL(data: {claimPhoneNumber: string, gameCode: string, gameplay: number, totalRewardClaimed: number, name: string, playerPhone: string}) {
  return `https://api.whatsapp.com/send?phone=${
    data.claimPhoneNumber
  }&text=Saya%20ingin%20mengklaim%20hadiah%20%2AGame%20Scratch%2A.%0AGame%20Code%3A%20${
    data.gameCode
  }%0A.%20Gameplay%3A%20${data?.gameplay}%0AReward%3A%20${thousandSeparator(
    data.totalRewardClaimed || 0
  )}%0A.%20%28${data.name}%20-%20${data.playerPhone}%29`;
}