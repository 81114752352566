import React, { useEffect, useState } from "react";
import Inventory_Ticket from "../../assets/images/Inventory_Ticket.svg";
import Inventory_Coins from "../../assets/images/Inventory_Coins.svg";
import thousandSeparator from "../../helpers/priceFormat";
import AnimatedNumber from "react-animated-numbers";
import useSound from "use-sound";
import levelUpSFX from "../../assets/sound/levelup.mp3";

interface InventoryGameBarProps {
  sessionDataStatus: any;
  reward: number;
}

const InventoryGameBar: React.FC<InventoryGameBarProps> = ({
  sessionDataStatus,
  reward,
}) => {

  const [animatedReward, setAnimatedReward] = useState(reward);
  const [prevReward, setPrevReward] = useState(reward);
  const [fadeInText, setFadeInText] = useState("");
  const [playLevelUp] = useSound(levelUpSFX);

  useEffect(() => {
    const formattedValue = thousandSeparator(reward);
    setAnimatedReward(reward);
    if (reward !== prevReward) {
      const diff = reward - prevReward;
      setFadeInText(`+${thousandSeparator(diff)}`);
      setTimeout(() => {
        setFadeInText("");
      }, 2000);
      const musicStatus = localStorage.getItem('musicStatus');
      if (musicStatus === 'on') {
        playLevelUp();
      }
    }
    setPrevReward(reward);
  }, [reward, prevReward]);

  return (
    <div className="grid grid-cols-10 gap-2.5">
      <div
        className="flex items-center justify-between px-4 h-[38px] col-span-6"
        style={{
          background: "#FFFFFF",
          border: "2px solid #FFC85E",
          borderRadius: "100px",
          width: "100%",
          boxShadow:
            "0px 4px 0px #BF7607, inset 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex">
          <img src={Inventory_Coins} alt="Inventory_Ticket" className="mr-1" />
          <span className="font-bold text-black text-[12px]">Reward</span>
        </div>

        <div className="relative flex justify-end font-bold text-black text-[14px] w-16">
          {fadeInText && (
            <div className="absolute bottom-7 text-green-500 text-sm animate-fade-out" style={{ animation: "fadeInAndOut 2s ease forwards" }}>
              {fadeInText}
            </div>
          )}
          <AnimatedNumber
            className="text-right"
            includeComma
            animateToNumber={animatedReward}
          />
        </div>
      </div>
      <div
        className="flex items-center justify-between col-span-4 h-[38px] px-4"
        style={{
          background: "#FFFFFF",
          border: "2px solid #FFC85E",
          borderRadius: "100px",
          width: "100%",
          boxShadow:
            "0px 4px 0px #BF7607, inset 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex">
          <img src={Inventory_Ticket} alt="Inventory_Ticket" className="mr-1" />
          <span className="font-bold text-black text-[12px]">Round</span>
        </div>
        <div className="font-bold text-black text-[14px]">
          {sessionDataStatus?.gamePlayerHistory.currentRound || 0}/
          {sessionDataStatus?.game?.max_round_per_gameplay_per_user || 0}
        </div>
      </div>
    </div>
  );
};

export default InventoryGameBar;
