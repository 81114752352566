import Background from "../../assets/images/background-landing-page.svg";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";

const Landing = () => {
  return (
    <div className="flex justify-center bg-black">
      <div className="w-full max-w-[390px]">
        <div
          className="container flex flex-col justify-end h-screen bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="flex flex-col items-center justify-between h-[33vh] px-[50px] py-3">
            <h1 className="font-[900] text-xl text-center text-[#FED32F]">
              Scratch & Win is your ticket to instant excitement and prizes!
            </h1>
            <p className="text-xs font-medium text-center text-white">
              Win cash and more in this thrilling online game. Join now and
              scratch your way to victory!
            </p>
            <LinkTerms theme="light" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
