/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import Container from "../../components/TailwindCustom/Container2";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuthApp from "../../hooks/useAuthApp";
import AppButton from "../../components/TailwindCustom/AppButton";
import Modal from "../../components/modal/Modal";
import LinkTerms from "../../components/TailwindCustom/LinkTerms";
import toast from "react-hot-toast";
import IconInfo from "../../assets/images/icon-info.svg";
import LogoGame from "../../assets/images/Logo.svg";
import { getErrorMessage, getErrorCode } from '@qlibs/react-components';
import { httpRequest } from "../../helpers/api";
import LoadingBlock from '../../components/LoadingBlock';
import { PlaySystemNotif, HandlePointClick } from "../../components/AudioTrigger/SfxTrigger";

const AuthPage = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState({
    phone: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalPlayed, setShowModalPlayed] = useState(false);

  const [modalMessage, setModalMessage] = useState<string>("");
  const { gameCode } = useParams();
  const navigate = useNavigate();
  const { isAuthLoading, doLogin, authError, setAuthError } = useAuthApp();

  const [isLoadingGameCheck, setIsLoadingGameCheck] = useState(false);
  const [errorGameCheck, setErrorGameCheck] = useState<'not_found' | 'inactive' | 'unknown_error'>();

  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  React.useEffect(() => {
    if (authError) {
      setModalMessage(authError);
      setShowModal(true);
    }
  }, [authError]);

  const isFormValid = name.trim() !== "" && phone.trim() !== "";

  function validatePhoneNumber(phoneNumber: string) {
    const regex = /^[0-9+\s-]{9,15}$/;

    return regex.test(phoneNumber);
  }

  useEffect(() => {
    checkGame();
  }, []);

  useEffect(() => {
    if (phone.length && !validatePhoneNumber(phone)) {
      setError((prev) => {
        return {
          ...prev,
          phone: true,
        };
      });
    } else {
      setError((prev) => {
        return {
          ...prev,
          phone: false,
        };
      });
    }
  }, [phone]);

  const hasError = useMemo(() => {
    return Object.values(error).some((item) => item);
  }, [error]);

  const checkGame = async () => {
    setIsLoadingGameCheck(true);
    try {
      const res = await httpRequest.get(
        `/play/${gameCode}/check-valid-game-code`,
      );

      if (res?.data?.payload?.status === 'inactive') {
        setErrorGameCheck('inactive');
      }

      setIsLoadingGameCheck(false);
    } catch (err) {
      // toast.error(getErrorMessage(err));
      const errorCode = getErrorCode(err)
      if (errorCode === 'error_in_game_code_check_not_found' || getErrorMessage(err).toLowerCase().includes('not found')) {
        setErrorGameCheck('not_found');
      } else {
        setErrorGameCheck('unknown_error');
      }
      setIsLoadingGameCheck(false);
    }
  }

  const doStart = async (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      toast.error("Please fill in all the inputs!");
      return;
    }

    try {
      const res = await httpRequest.post(
        `/play/${gameCode}/check-user-already-played`,
        {
          phone,
        }
      );

      const resData = res.data.payload;

      // const response = await httpRequest.post(
      //   `/play/${gameCode}/check-user-already-expired`,
      //   {
      //     phone
      //   }
      // );

      doLogin({ name, phone }, () => {
        if (resData.isPlayed) {
          if (resData.checkExpired?.enterGame !== undefined && resData.checkExpired?.canPlay !== undefined && !resData?.checkExpired?.enterGame && !resData?.checkExpired?.canPlay) {
            setShowModal(true);
            setModalMessage('Game already expired');
          } else {
            setShowModalPlayed(true);
          }
        } else {
          // if (response.data.payload.isExpired) {
          //   setShowModal(true);
          //   setModalMessage('Game already expired');
          // } else {
          //   navigate(`/play/${gameCode}/start`);
          //   console.log("Login success");
          // } 
          if (resData) {
            if (resData.checkExpired?.enterGame !== undefined && resData.checkExpired?.canPlay !== undefined && !resData?.checkExpired?.enterGame && !resData?.checkExpired?.canPlay) {
              setShowModal(true);
              setModalMessage('Game already expired');
            } else {
              navigate(`/play/${gameCode}/start`);
              console.log("Login success");
            }
          }
        }
      });
    } catch (error) {
      // toast.error(getErrorMessage(error));
      setShowModal(true);
      setModalMessage(getErrorMessage(error));
    }
  };

  React.useEffect(() => {
    console.log('logo loaded', isLogoLoaded);
  }, [isLogoLoaded]);

  return (
    <>

      <Container>
        {isLoadingGameCheck && <LoadingBlock />}

        <div className="flex flex-col items-center justify-between h-screen px-[25px] py-3 gap-[54px]">
          {/* ukuran 95% */}
          <div className="flex flex-col items-center justify-between space-y-6 mt-[50px] h-[95vh]">
            <img src={LogoGame} alt="logo" className="scale-[1.5]" onLoad={() => setIsLogoLoaded(true)} style={{ display: isLogoLoaded ? 'block' : 'none' }} />
            {isLogoLoaded ? (
              <>
                {!!errorGameCheck && (
                  <div className="flex items-start px-[10px] py-[8px] text-lg text-white bg-[#E53935] rounded space-x-1">
                    <img src={IconInfo} alt="icon info" className="w-8 h-8" />
                    <span className="text-left">
                      {errorGameCheck === 'not_found'
                        ? 'GAME IS NOT FOUND'
                        : errorGameCheck === 'inactive'
                          ? 'GAME IS NOT FOUND ~'
                          : 'FAILED! PLEASE TRY AGAIN ~'}
                    </span>
                  </div>
                )}

                {!errorGameCheck && (
                  <h2 className="font-semibold text-center text-black text-normal">
                    Enter your name & phone number to play scratch game
                  </h2>
                )}

                {!isLoadingGameCheck && !errorGameCheck && (
                  <form className="space-y-3" onSubmit={doStart}>
                    <input
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="off"
                      placeholder="Enter your name"
                      type="text"
                      className="w-full bg-white text-black input text-center input-bordered h-[56px] border-[#CFCFCF]"
                    />

                    <input
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="off"
                      placeholder="Enter your phone number"
                      type="text"
                      className="w-full bg-white text-black input text-center input-bordered h-[56px] border-[#CFCFCF]"
                    />

                    {error.phone && (
                      <div className="flex items-start px-[8px] py-[6px] text-xs text-white bg-[#E53935] rounded space-x-1">
                        <img src={IconInfo} alt="icon info" className="w-4 h-4" />
                        <span className="text-left">
                          The phone number must be between 9 and 15 characters and
                          can only include numbers, dash, plus and spacing.
                        </span>
                      </div>
                    )}

                    <AppButton
                      text="Let's Start"
                      disabled={hasError}
                      type="submit"
                      loading={isAuthLoading}
                    />
                  </form>
                )}
              </>
            ) : (
              <div className="flex justify-center items-center h-[76vh]">
                <div className='text-center'>
                  <span className="loading loading-spinner text-black text-2xl w-[10vw]" />
                  <br />
                  <span className="text-black font-bold">Please Wait...</span>
                </div>
              </div>
            )}
          </div>
          <div>
            {/* ukuran 5% */}
            <LinkTerms theme="dark" />
          </div>
        </div>
      </Container>

      <Modal open={showModalPlayed} showClose={true} onClose={() => setShowModalPlayed(false)} >
        <div className="flex flex-col items-center space-y-3 py-5 text-center text-black">
          <h3 className="text-lg font-semibold">User has already played</h3>
          <p className="text-md ">
            You've already played, you can see the history of your game
            {/* <Link
              to={`/play/${gameCode}/histories`}
              className="ml-1 text-blue-500 underline"
            >
              here
            </Link> */}
          </p>
          <AppButton
            className="w-[85%]"
            text="See History"
            onClick={() => {
              navigate(`/play/${gameCode}/histories`);
            }}
          />

        </div>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setAuthError('');
        }}
        showClose={false}
      >
        <div className="flex flex-col items-center space-y-4 text-center text-black">
          {modalMessage === 'User not registered.' ? (
            <>
              <h3 className="text-lg font-semibold">
                Phone number is not registered
              </h3>
              <p className="text-xs">
                Your phone number is not registered as member so you can’t play
                this game.
              </p>
            </>
          ) : (
            <>
              <h3 className="text-[20px] font-semibold">{modalMessage}</h3>
            </>
          )}

          <AppButton
            text="OK"
            onClick={() => {
              setShowModal(false);
              setAuthError('');
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AuthPage;
